// Vendors
import Vue from "vue";

// Custom Components
//import navMobile from './components/navmobile.vue';

class App {

    constructor() {

    }

    init() {

        new Vue({

            el: '#app',
            delimiters: ['<%', '%>'],
            components: {

                // Custom Components
                //'navmobile': navMobile,
            },

            data() {

                return {};
            }
        });
    }
}

const app = new App();

app.init();
